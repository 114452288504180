import _ from 'lodash';
import Head from 'next/head';
import React, { useState } from 'react';
import { Container, Button, Form, Alert } from 'react-bootstrap';

import Api from 'src/data/api';
import styles from 'src/components/login/login.module.scss';

function RequestPasswordReset({ onComplete }) {
    const [emailAddress, setEmailAddress] = useState('');
    const [submitEnabled, allowSubmit] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        allowSubmit(false);

        const errors = {};
        if (!emailAddress) {
            errors.emailAddress = "Please enter your email address.";
        }
        setErrors(errors);
        if (_.size(errors) > 0) {
            allowSubmit(true);
            return;
        }

        try {
            const res = await Api.request({
                method: 'POST',
                url: `/auth/reset_password`,
                data: _.pickBy(
                    {
                        email: emailAddress,
                    },
                    val => !!val
                ),
            });
            onComplete(res.data.message);
        } catch (err) {
            allowSubmit(true);
            if (err.response) {
                setMessage(err.response.data['error_description']);
            } else {
                setMessage("An unexpected error occurred. Please try again later.");
            }
        }
    };

    return (
        <div className={styles.contentWrapper}>
            <Head>
                <title>Request Password Reset - Tote Dashboard</title>
            </Head>
            <Container className={styles.content}>
                <div className={styles.brandLogo}></div>

                {message &&
                    <Alert variant="danger">{message}</Alert>
                }

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="input-email">
                        <Form.Label>Enter your email address</Form.Label>
                        <Form.Control
                            type="email"
                            value={emailAddress}
                            onChange={e => setEmailAddress(e.target.value)}
                            required
                            isInvalid={errors.emailAddress}
                        />
                        <Form.Control.Feedback type="invalid">{errors.emailAddress}</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="light" type="submit" disabled={!submitEnabled} className="mt-3">
                        Request Password Reset
                    </Button>
                    <Button variant="link" type="button" onClick={() => onComplete()} className="mt-3">
                        Back
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

export default RequestPasswordReset;
