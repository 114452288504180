import React, { useState } from "react";
import Head from "next/head";
import { Container, Button, Form, Alert, Spinner } from "react-bootstrap";
import { useRouter } from "next/router";
import { connect } from "react-redux";

import Api from "src/data/api";
import * as actions from "src/redux/actions";
import RequestPasswordReset from "src/components/login/requestPasswordReset";
import styles from "src/components/login/login.module.scss";
import { LOCAL_JWT_TOKEN } from "src/lib/constants";
import Link from "next/link";
import { useShopifyAppBridge } from "../components/shopify/appBridge";

function Login({ dispatch }) {
    const { getAppBridge } = useShopifyAppBridge();
    const isShopify = getAppBridge() !== null;

    const router = useRouter();

    const [resetPasswordVisible, showResetPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [submitEnabled, allowSubmit] = useState(true);
    const [message, setMessage] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        allowSubmit(false);

        if (!email || !password) {
            setMessage("Please enter your email and password to proceed.");
            allowSubmit(true);
            return;
        }

        try {
            const { data } = await Api.request({
                method: "POST",
                url: `/auth/login`,
                data: { email, password },
            });

            window.localStorage.setItem(LOCAL_JWT_TOKEN, data.token);
            await dispatch(actions.loadUser());
            await router.push(router.query.next || "/");
        } catch (err) {
            allowSubmit(true);
            console.error(err);
            if (err?.response.status === 401) {
                setMessage("Your username or password is incorrect.");
            } else {
                setMessage(
                    "An unexpected error occurred. Please try again later."
                );
            }
        }
    };

    if (resetPasswordVisible) {
        return (
            <RequestPasswordReset
                onComplete={(msg) => {
                    if (msg) {
                        setMessage(msg);
                    }
                    showResetPassword(false);
                }}
            />
        );
    }

    return (
        <div className={styles.contentWrapper}>
            <Head>
                <title>Login - Tote Dashboard</title>
            </Head>

            <Container className={styles.content}>
                <div className={styles.brandLogo}></div>

                {message && <Alert variant="danger">{message}</Alert>}

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="input-email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="input-password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Button
                        variant="light"
                        type="submit"
                        disabled={!submitEnabled}
                        className="mt-3"
                    >
                        {!submitEnabled ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            "Login"
                        )}
                    </Button>
                    <Button
                        variant="link"
                        type="button"
                        onClick={() => showResetPassword(true)}
                        className="mt-3"
                    >
                        Reset password
                    </Button>
                </Form>
            </Container>
        </div>
    );
}

export default connect()(Login);
